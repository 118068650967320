<!--
 * @Description: 内容发布--资讯帖子--分类配置列表页面
 * @Author: 小广
 * @Date: 2019-06-21 10:21:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-13 10:28:53
 -->

<template>
  <div class="infoPostCategoryList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="分类名称" v-model="searchParams.value"></v-input>
        <v-input label="创建人"  v-model="searchParams.inuser"></v-input>
        <v-select label="所属App" v-model="searchParams.appType" :options="appTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-select2
          label="公司"
          :width="180"
          placeholder="请选择公司"
          v-model="searchParams.regionId"
          v-bind="regionParams"
        ></v-select2>
        <v-select
          label="层级"
          v-model="searchParams.hierarchy"
          :options="hierarchyOps"
        />
        <v-datepicker
          label="创建时间"
          type="rangedatetimer"
          :maxDate="maxDate"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime">
        </v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="((userType !== '106' && scope.row.hierarchy === '2') || userType === '106') && scope.row.codeLevel !== '1'" text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getCategoryListURL } from './api'
import { statusOps, statusMap, codeLevelMap, appTypeOps, hierarchyMap, hierarchyOps } from './map'
import moment from 'moment'
import { regionParams } from 'common/select2Params'

export default {
  data () {
    return {
      maxDate: moment().format('YYYY-MM-DD HH:mm'),
      searchUrl: getCategoryListURL,
      statusOps: statusOps(1),
      appTypeOps: appTypeOps(1),
      regionParams,
      searchParams: {
        value: '',
        inuser: '',
        status: undefined,
        appType: undefined,
        startTime: '',
        endTime: '',
        regionId: '',
        hierarchy: undefined
      },
      headers: [
        {
          prop: 'value',
          label: '分类名称'
        },
        {
          prop: 'appType',
          label: '所属App'
        },
        {
          prop: 'codeLevelStr',
          label: '分类层级',
          formatter (row) {
            return codeLevelMap[row.codeLevel]
          }
        },
        {
          prop: 'superCodeword',
          label: '上级分类',
          formatter (row) {
            return row.superCodeword || '-'
          }
        },
        {
          prop: 'regionName',
          label: '所属组织'
        },
        {
          prop: 'hierarchy',
          label: '层级',
          formatter (row) {
            return hierarchyMap[row.hierarchy]
          }
        },
        {
          prop: 'statusStr',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'inuser',
          label: '创建人',
          formatter (row) {
            return row.inuser || '-'
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      hierarchyMap,
      hierarchyOps,
      userType: ''
    }
  },
  mounted () {
    let userType = this.$store.state.userInfo.userType
    // 平台和集团均有权限
    if (userType === '106' || userType === '100') {
      userType = '106'
    }
    this.userType = userType
  },

  methods: {
    create () {
      this.$router.push({
        name: 'infoPostCategoryForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'infoPostCategoryForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
